<template>
  <v-col sm="12" class="tw-w-full">
    <loader :color="companyColor" v-if="loading" :loading="loading"/>
  <v-col sm="12" class="tw-flex tw-flex-col tw-w-full tw-p-0" v-else >
    <button
        class="tw-flex tw-items-center tw-mt-20 lg:tw-mt-6 lg:tw-mb-5 overview-btn"
        @click="backToAllBookings"
        :style="{ color: companyColor }"
    >
      <icons name="arrow-back" :color="companyColor" />
      <span class="tw-ml-4">Back</span>
    </button>

      <search-trip-card/>

    <div class="tw-flex tw-flex-row tw-items-center tw-mt-10 tw-w-full tw-pb-5">
      <v-divider light  />
    </div>
<!--    <location-finder/>-->
    <!--DEPARTURE-->
    <div class="tw-mt-10 tw-p-5 lg:tw-p-8 tw-w-full fleet-card" v-if="pendingTrips.length">
      <div class="tw-flex tw-flex-wrap tw-flex-row tw-w-full lg:tw-pr-8 ">
        <label class="booking-card-title tw-ml-5" :style="{color:companyColor}">Departure:
          <span style="font-weight: normal">Available Buses</span></label>
        <v-spacer/>
      </div>

      <div class=" tw-flex tw-pl-1 " id="departureTrips">
        <v-expansion-panels
            flat
            v-model="departurePanel"
        >
          <trip-bus-card :color="companyColor" @getTripAndSeatsDetails="handlePassengerDepartureDetails"
                         v-for="(trip,index) in pendingTrips" :trip="trip" :key="index+'departure'" @handleNext="handleNext" />
        </v-expansion-panels>
      </div>
    </div>

<!--    ARRIVAL-->
    <div class="tw-mt-10 tw-p-8 tw-w-full fleet-card" v-if="showArrival" >
      <div class="tw-flex tw-flex-row tw-w-full tw-pr-8 ">
        <label class="booking-card-title tw-ml-5" :style="{color:companyColor}">Arrival:
          <span style="font-weight: normal">Available Buses</span></label>
        <v-spacer/>
      </div>

      <div class=" tw-flex tw-pl-1 ">
        <v-expansion-panels
            v-model="arrivalPanel"
            multiple flat>
          <trip-bus-card :color="companyColor" @getTripAndSeatsDetails="handlePassengerDepartureDetails"
                         v-for="(trip,index) in arrivals" :trip="trip" :key="index+'arrival'" />
        </v-expansion-panels>
      </div>
    </div>
  </v-col>
  </v-col>
</template>

<script>

// import AvatarProgress from "../../reuseables/AvatarProgress";
import Icons from "../../reuseables/Icons";
import TripBusCard from "./TripBusCard";
import Loader from "../../reuseables/Loader";
import SearchTripCard from "./SearchTripCard.vue";
import {mapGetters} from "vuex";
import dayjs from "dayjs";

export default {
  name: "AddBooking",
  components: {SearchTripCard, Loader, TripBusCard, Icons},
  data() {
    return {
      companyColor:"",
      companyId: "",
      departures:[],
      terminals:[],
      arrivals:[],
      filterBy:{
        departure:"",
        arrival: "",
        seat:"",
        departureDate: null,
        arrivalDate: null,
      },
      staffData:{},
      departureMenu: false,
      arrivalMenu: false,
      seats:[1,2,3,4,5,6,7],
      filterDate: null,
      filterMenu: false,
      filterSearch: null,
      filterSearchMenu: false,
      loading: false,
      departurePanel : 0,
      arrivalPanel : [],
      companyData:{},
      trips:[],
      passengerDepartureDetails:{},
      minDate: (new Date(new Date().getTime() + (60 * 60 * 1000))).toISOString().substr(0, 10),
      showArrival:false
    };
  },
  computed:{
    ...mapGetters("tripAndTravellerDetails", ["pendingTrips"]),
  },
  watch:{
    pendingTrips:{
      handler : function (val){
        if(val.length > 0){
          this.$store.dispatch("seatsSelection/setUserSelectedTrip",val[0])
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handlePassengerDepartureDetails(tripDetail){
      this.passengerDepartureDetails.tripId = tripDetail.tripId
      this.passengerDepartureDetails.seats = tripDetail.seats
      this.passengerDepartureDetails.tripFare = tripDetail.tripAmount
      sessionStorage.setItem("passengerDetail",JSON.stringify(this.passengerDepartureDetails))
      this.$store.dispatch("tripAndTravellerDetails/updateTripAndTravellerDetails",this.passengerDepartureDetails)
      console.log(this.passengerDepartureDetails, "trip and seats")
    },

    handleNext(){
      let currentSelectedSeats = JSON.parse(sessionStorage.getItem('currentSelectedSeats'))
      if (currentSelectedSeats &&
          this.passengerDepartureDetails.seats && this.passengerDepartureDetails.seats.length >= 0){
        this.$store.dispatch("bookingComponent/setCurrentComponent",
            "TravellerDetails");
        sessionStorage.setItem("bookingComponent", "TravellerDetails");
      }
      else {
        this.$displaySnackbar({
          message: "You have not selected a seat(s) yet",
          success: false,
        })
      }
    },

    availableSeats(seats){
     let seatsAvailable = []
      seats.forEach(seat => {
        if (seat.status === 'AVAILABLE'){
          seatsAvailable.push(seat)
        }
      })
      return seatsAvailable;
    },
    backToAllBookings(){
      sessionStorage.setItem("bookingComponent", "allBookings");
      this.$store.dispatch("bookingComponent/setCurrentComponent", "allBookings");
    },

    getDateForSearchQuery(date){
      if (date){
        return date
      }
      else {
        return dayjs().format('YYYY-MM-DD')
      }
    },
    convertTo24Hour(time) {
      const hours = parseInt(time.substr(0, 2));
      if(time.indexOf('am') !== -1 && hours === 12) {
        time = time.replace('12', '0');
      }
      if(time.indexOf('pm')  !== -1 && hours < 12) {
        time = time.replace(hours, (hours + 12));
      }
      let hours12 = time.replace(/(am|pm)/, '');
      let [dHours, dMinutes] = hours12.split(':');
      if (dHours.length === 1){
        hours12 = "0"+hours12
      }
      console.log(dMinutes)
      return hours12;
    },
  },
 async created() {
    let companyData = JSON.parse(sessionStorage.getItem("companyData"));
    let staffData = JSON.parse(sessionStorage.getItem("staffData"))
    let passengerDetail = JSON.parse(sessionStorage.getItem('passengerDetail'))
    let currentSelectedSeats = JSON.parse(sessionStorage.getItem('currentSelectedSeats'))
   let queryData = sessionStorage.getItem("queryData")
    this.companyData = companyData;
    if (companyData) {
      this.companyColor = companyData.companyColor;
      this.companyId = companyData.id;
    }
    if (staffData){
      this.staffData = staffData
    }

   if (passengerDetail && currentSelectedSeats){
     this.passengerDepartureDetails = passengerDetail
   }
   if (queryData){
     queryData = JSON.parse(queryData)

   }
  }
};
</script>

<style lang="scss" scoped>
.booking-card{
  height: 170px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background: #FDFFFC;
  box-shadow: 0 4.03659px 40.3659px rgba(79, 79, 79, 0.02);
}
.booking-card-title{
  font-family: Inter,serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px !important;
  line-height: 22px;
}
.optional{
  font-family: Inter,serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px !important;
  line-height: 15px;
  color: #C4C4C4;
}

.fleet-card{
  flex: none!important;
  background-color: #FDFFFC;
  border-radius: 10px;
}

.fleet-card-title{
  font-family: Inter, serif!important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px !important;
  line-height: 22px;
}

.filter{
  font-family: Inter, serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 17px;
  color: #4F4F4F;
}
</style>
